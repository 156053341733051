import React from 'react';

const IconLetterG = () => (
  <svg id='iconLetterG' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 148.8 318.3'>
  <g>
    <g>
      <path style={{fill: '#bbbbbb'}} d='M27.2,226.1c-13-13-19.5-28.8-19.5-47.2s6.5-34.1,19.5-47.1c13-13.1,28.8-19.6,47.2-19.6s34.1,6.5,47.1,19.6c13.1,13,19.6,28.7,19.6,47.1s-6.5,34.1-19.6,47.2c-13,13-28.7,19.5-47.1,19.5s-34.1-6.5-47.2-19.5h0ZM27.2,292.7c-13-13-19.5-28.7-19.5-47.1h33.3c0,9.2,3.3,17,9.8,23.5,6.5,6.6,14.4,9.8,23.6,9.8s17-3.3,23.5-9.8c6.6-6.5,9.8-14.4,9.8-23.5h33.3c0,18.4-6.5,34.1-19.6,47.1-13,13.1-28.7,19.6-47.1,19.6s-34.1-6.5-47.2-19.6h0ZM97.9,202.5c6.6-6.5,9.8-14.4,9.8-23.6s-3.3-17-9.8-23.5c-6.5-6.6-14.4-9.8-23.5-9.8s-17.1,3.3-23.6,9.8c-6.5,6.5-9.8,14.4-9.8,23.5s3.3,17.1,9.8,23.6,14.4,9.8,23.6,9.8,17-3.3,23.5-9.8Z'/>
    </g>
    <g id='iconLetterGrotate'>
      <path  style={{fill: 'transparent'}} d='M70.9,54.2h20.7l21.3,17.3c3.7-6.4,5.5-13.6,5.5-21.5,0-12.1-4.3-22.5-13-31.1-8.6-8.6-19-13-31.1-13s-22.6,4.3-31.2,13c-8.6,8.6-12.9,19-12.9,31.1s1.8,15.1,5.5,21.6l21.3-17.4h13.8Z'/>
      <path style={{fill: '#ffffff'}} d='M89.9,65.6c4.3-4.3,6.5-9.5,6.5-15.6s-2.2-11.2-6.5-15.5c-4.3-4.3-9.5-6.5-15.5-6.5s-11.3,2.2-15.6,6.5c-4.3,4.3-6.5,9.5-6.5,15.5s2.2,11.3,6.5,15.6,9.5,6.5,15.6,6.5,11.2-2.2,15.5-6.5Z'/>
      <path style={{fill: '#ef86b5'}} d='M95.3,57.4c-1,3.2-2.8,6.1-5.4,8.7-4.3,4.3-9.5,6.5-15.5,6.5s-11.3-2.2-15.6-6.5-4.3-5.4-5.4-8.6l-17.6,14.3c2,3.4,4.4,6.6,7.4,9.6h0c8.6,8.6,19,12.9,31.2,12.9s22.5-4.3,31.1-12.9c3-3,5.5-6.2,7.4-9.6l-17.6-14.3Z'/>
    </g>
  </g>
</svg> 
);

export default IconLetterG;