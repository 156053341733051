import React from 'react';

const IconShopify = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300"> 
  <g>
    <g>
      <path className="fill-lemongreen" d="M245.9,61.4c-.2-1.5-1.5-2.3-2.5-2.4-1.1,0-23.4-1.7-23.4-1.7,0,0-15.5-15.4-17.2-17.1-1.7-1.7-5-1.2-6.3-.8-.2,0-3.4,1-8.7,2.7-5.2-14.9-14.3-28.6-30.4-28.6s-.9,0-1.4,0c-4.6-6-10.2-8.7-15.1-8.7-37.4,0-55.3,46.8-60.9,70.6-14.5,4.5-24.9,7.7-26.2,8.1-8.1,2.5-8.4,2.8-9.4,10.5-.8,5.8-22,170.1-22,170.1l165.5,31,89.7-19.4s-31.5-212.9-31.7-214.3h0ZM178.7,44.9l-14,4.3c0-1,0-2,0-3,0-9.3-1.3-16.7-3.3-22.6,8.3,1,13.8,10.5,17.3,21.3ZM151.1,25.4c2.3,5.8,3.8,14,3.8,25.2s0,1.1,0,1.6c-9.1,2.8-19,5.9-28.9,9,5.6-21.5,16-31.9,25.1-35.8ZM140,14.9c1.6,0,3.2.5,4.8,1.6-12,5.6-24.9,19.9-30.3,48.3l-22.9,7.1c6.4-21.7,21.5-57,48.3-57h0Z"/>
      <path className="fill-forestgreen" d="M243.4,59c-1.1,0-23.4-1.7-23.4-1.7,0,0-15.5-15.4-17.2-17.1-.6-.6-1.5-1-2.4-1.1l-12.5,256,89.7-19.4s-31.5-212.9-31.7-214.3c-.2-1.5-1.5-2.3-2.5-2.4"/>
      <path className="fill-white" d="M157.5,108.6l-11.1,32.9s-9.7-5.2-21.6-5.2c-17.4,0-18.3,10.9-18.3,13.7,0,15,39.2,20.8,39.2,56s-17.6,45.5-41.2,45.5-42.9-17.7-42.9-17.7l7.6-25.1s14.9,12.8,27.5,12.8,11.6-6.5,11.6-11.2c0-19.6-32.1-20.5-32.1-52.7s19.5-53.3,58.7-53.3,22.6,4.3,22.6,4.3"/>
    </g>
  </g>
</svg>
);

export default IconShopify;