import React from 'react';

const IconVS= () => ( 
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300"> 
      <g>
        <g>
          <g>
            <path className="fill-darkpurple" d="M49.7,230.6c-3.4,2.9-8.1,3.6-12.2,1.9l-21.4-9c-4.4-1.8-7.2-6.1-7.2-10.8v-125.4c0-4.7,2.9-9,7.2-10.8l21.4-8.8c4.1-1.7,8.8-1,12.2,1.9l4.8,4c-2.9-2.1-7-1.4-9.1,1.5-.8,1.1-1.2,2.4-1.2,3.8v142.2c0,3.6,2.9,6.5,6.5,6.5s2.7-.4,3.8-1.2l-4.8,4.1h0Z"/>
            <path className="fill-purple3" d="M16.2,223.5c-4.4-1.8-7.2-6.1-7.2-10.8v-1c0,3.7,3.1,6.8,6.9,6.7,1.9,0,3.6-.8,4.9-2.2L202.9,14.9c5.3-5.3,13.3-6.6,20-3.4l58.1,28c6.1,2.9,10,9.1,10,15.9v.7c0-6.2-5-11.1-11.1-11.1s-5.1.9-7,2.5L54.5,226.5l-4.8,4.1c-3.4,2.9-8.1,3.6-12.2,1.9l-21.4-9h.1Z"/>
            <path className="fill-purple2" d="M16.2,76.6c-4.4,1.8-7.2,6.1-7.2,10.8v1c0-3.7,3.1-6.8,6.9-6.7,1.9,0,3.6.8,4.9,2.2l182.2,201.4c5.3,5.3,13.3,6.6,20,3.4l58.1-28c6.1-2.9,10-9.1,10-15.9v-.7c0,6.2-5,11.1-11.1,11.1s-5.1-.9-7-2.5L54.5,73.7l-4.8-4.1c-3.4-2.8-8.1-3.5-12.2-1.8l-21.4,8.8h.1Z"/>
            <path className="fill-purple" d="M222.9,288.7c-6.7,3.2-14.8,1.9-20-3.4,4.1,4,10.6,4,14.6,0s3-4.5,3-7.3V22.3c0-5.7-4.6-10.4-10.3-10.4s-5.4,1.1-7.3,3c5.3-5.3,13.3-6.6,20-3.4l58.1,27.9c6.1,2.9,10,9.1,10,15.9v189.5c0,6.8-3.9,13-10,15.9l-58.1,27.9h0Z"/>
          </g>
        </g>
      </g>
    </svg>
);

export default IconVS;