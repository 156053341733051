import React, { Suspense, useRef, useState, useEffect } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import * as THREE from 'three';

const Model = () => {
  const [model, setModel] = useState(null);
  const modelRef = useRef();
  const [scale, setScale] = useState(2);

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load('/models/final/scene7.gltf', (gltf) => {
      const scene = gltf.scene;
      scene.traverse((node) => {
        if (node.isMesh) {
          node.castShadow = true; 
          node.material = node.material.clone();
          node.material.color = new THREE.Color(
            node.material.color.r * 1.2,
            node.material.color.g * 1.2,
            node.material.color.b * 1.2
          ); 
        }
      });
      setModel(scene);
    });

    const updateScale = () => {
      if (window.innerWidth > 1100) {
        setScale(8); 
      } else {
        setScale(7);
      }
    };

    window.addEventListener('resize', updateScale);
    updateScale();

    return () => {
      window.removeEventListener('resize', updateScale);
    };
  }, []);

  // Animacja rotacji modelu
  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.01;
    }
  });

  return model ? <primitive ref={modelRef} object={model} scale={[scale, scale, scale]} /> : null;
};

const ThreeLetterGltf = () => {
  return (
    <Canvas shadows camera={{ position: [0, 5, 15], fov: 50 }}>
      <ambientLight intensity={0.3} />
       
      <directionalLight position={[10, 10, 5]} intensity={1.5} castShadow />
       
      <directionalLight position={[-10, -10, -10]} intensity={0.2} />

      <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[0, -2.5, 0]}>
        <planeGeometry args={[50, 50]} />
        <shadowMaterial opacity={0.1} />
      </mesh>

      <Suspense fallback={null}>
        <Model />
      </Suspense>

      <OrbitControls
        enableDamping={false}
        dampingFactor={0.25}
        screenSpacePanning={true}
        minDistance={10}
        maxDistance={50}
        enableZoom={false}
        enableRotate={false}
        enablePan={false}
      />
    </Canvas>
  );
};

export default ThreeLetterGltf;
