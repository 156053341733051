import React, { useEffect, useState } from 'react'; 
import IconLetterG from './snippets/IconLetterG';

function PagePreloader() {
     
    return (
        <div id='preloader'>
            <div className='page-preloader'>
                <IconLetterG />
            </div>
        </div>
    );
}

export default PagePreloader;
