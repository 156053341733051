import React from 'react';

const LogoHeaderColor = () => (
  <svg className="welcome__header animate__jackInTheBox animate__animated" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 886.6 556.9" id="logoMain">
  <g>
    <g>
      <path className="cls-1"  d="M705.8,45.9c-3.8,3.8-8.4,5.7-13.7,5.7s-9.9-1.9-13.7-5.7c-3.8-3.8-5.7-8.5-5.7-14s1.9-9.9,5.6-13.7c3.7-3.8,8.3-5.7,13.9-5.7s9.9,1.9,13.7,5.7c3.8,3.8,5.7,8.4,5.7,13.7s-1.9,10.2-5.7,14Z"/>
      <path d="M228.5,180.6c-13-13-19.5-28.8-19.5-47.2s6.5-34.1,19.5-47.1c13-13.1,28.8-19.6,47.2-19.6s34.1,6.5,47.1,19.6c13.1,13,19.6,28.7,19.6,47.1v66.7h-33.3v-66.7c0-9.2-3.3-17-9.8-23.5-6.5-6.6-14.4-9.8-23.5-9.8s-17.1,3.3-23.6,9.8c-6.5,6.5-9.8,14.4-9.8,23.5s3.3,17.1,9.8,23.6c6.5,6.5,14.4,9.8,23.6,9.8v33.4c-18.4,0-34.1-6.5-47.2-19.5h0Z"/>
      <path d="M380.5,180.6c-13-13-19.5-28.8-19.5-47.2v-66.7h33.3v66.7c0,9.2,3.3,17.1,9.8,23.6,6.5,6.5,14.4,9.8,23.6,9.8v33.4c-18.4,0-34.1-6.5-47.2-19.5h0ZM461.1,200.1V66.7h33.3v133.4h-33.3Z"/>
      <path d="M532.6,180.6c-13-13-19.5-28.8-19.5-47.2s6.5-34.1,19.5-47.1c13-13.1,28.8-19.6,47.2-19.6v33.4c-9.2,0-17.1,3.3-23.6,9.8-6.5,6.5-9.8,14.4-9.8,23.5s3.3,17.1,9.8,23.6c6.5,6.5,14.4,9.8,23.6,9.8s17-3.3,23.5-9.8c6.6-6.5,9.8-14.4,9.8-23.6V0h33.3v133.4c0,18.4-6.5,34.1-19.6,47.2-13,13-28.7,19.5-47.1,19.5s-34.1-6.5-47.2-19.5h0Z"/>
      <path d="M706.6,66.7v66.7c0,9.2,3.3,17,9.8,23.5,6.5,6.6,14.4,9.8,23.6,9.8v33.4c-18.4,0-34.1-6.5-47.2-19.6-13-13-19.5-28.7-19.5-47.1v-66.7h33.4Z"/>
      <path d="M772.7,180.6c-13-13-19.5-28.8-19.5-47.2s6.5-34.1,19.5-47.1c13-13.1,28.8-19.6,47.2-19.6s34.1,6.5,47.1,19.6c13.1,13,19.6,28.7,19.6,47.1v66.7h-33.3v-66.7c0-9.2-3.3-17-9.8-23.5-6.5-6.6-14.4-9.8-23.5-9.8s-17.1,3.3-23.6,9.8c-6.5,6.5-9.8,14.4-9.8,23.5s3.3,17.1,9.8,23.6c6.5,6.5,14.4,9.8,23.6,9.8v33.4c-18.4,0-34.1-6.5-47.2-19.5h0Z"/>
      <path d="M275.1,470.7c-13-13-19.5-28.8-19.5-47.2v-66.7h33.3v66.7c0,9.2,3.3,17.1,9.8,23.6s14.4,9.8,23.6,9.8v33.3c-18.4,0-34.1-6.5-47.2-19.5h0ZM355.6,490.2v-133.4h33.3v133.4h-33.3Z"/>
      <path d="M434.4,470.7c-13-13-19.5-28.8-19.5-47.2s6.5-34.1,19.5-47.1c13-13.1,28.8-19.6,47.2-19.6s34.1,6.5,47.1,19.6c13.1,13,19.6,28.7,19.6,47.1s-6.5,34.1-19.6,47.2c-13,13-28.7,19.5-47.1,19.5s-34.1-6.5-47.2-19.5h0ZM434.4,537.3c-13-13-19.5-28.7-19.5-47.1h33.3c0,9.2,3.3,17,9.8,23.5,6.5,6.6,14.4,9.8,23.6,9.8s17-3.3,23.5-9.8c6.6-6.5,9.8-14.4,9.8-23.5h33.3c0,18.4-6.5,34.1-19.6,47.1-13,13.1-28.7,19.6-47.1,19.6s-34.1-6.5-47.2-19.6h0ZM505.1,447.1c6.6-6.5,9.8-14.4,9.8-23.6s-3.3-17-9.8-23.5c-6.5-6.6-14.4-9.8-23.5-9.8s-17.1,3.3-23.6,9.8c-6.5,6.5-9.8,14.4-9.8,23.5s3.3,17.1,9.8,23.6,14.4,9.8,23.6,9.8,17-3.3,23.5-9.8Z"/>
      <path d="M590,470.7c-13-13-19.5-28.8-19.5-47.2v-66.7h33.3v66.7c0,9.2,3.3,17.1,9.8,23.6s14.4,9.8,23.6,9.8v33.3c-18.4,0-34.1-6.5-47.2-19.5h0ZM670.5,490.2v-133.4h33.3v133.4h-33.3Z"/>
      <path d="M752.9,470.7c-13-13-19.5-28.8-19.5-47.2v-133.4h33.3v133.4c0,9.2,3.3,17.1,9.8,23.6s14.4,9.8,23.6,9.8v33.3c-18.4,0-34.1-6.5-47.2-19.5h0Z"/>
      <g>
        <path d="M172.7,467c-13.8-15.4-20.7-34.1-20.7-55.9l.3-161.6,35,3.5v79.1h35.3v39.5h-35.3v39.5c0,10.9,3.5,20.2,10.3,28,6.9,7.7,15.2,11.6,25,11.6v39.5c-19.5,0-36.1-7.7-49.9-23.2h0Z"/>
        <path d="M187.3,253.6l-35-4.1V3.7h35.2l-.2,249.9Z"/>
      </g>
      <path className="cls-1"  d="M503.5,301.9c-4,5.8-9.5,9.4-16.5,10.7s-13.3,0-19.2-4.1l-14-9.7c-.5.8-1.1,1.6-1.7,2.3-5.2,6-9.6,11.8-13.7,18.2l14.8,10.2c11.6,8,24.4,10.8,38.3,8.2,13.9-2.6,24.9-9.7,32.9-21.3l-21.1-14.5h0Z"/>
      <g>
        <path d="M0,200.1l1.4-91.5,1.3-8.9-.8-24.4L2.9,4.1h28.9v80.1h31.8c17.5,0,32.5,5.7,44.9,17,12.5,11.3,18.7,25,18.7,40.9v58h-31.8v-58c0-8-4.2-17.9-10.4-23.5-6.2-5.7-12.6-10.5-21.3-10.5h-31.7c0,.1,0,92,0,92H0Z"/>
        <path className="cls-1"  d="M31.8,75.4c-10.1,11.5-20.3,23-30.4,34.5h30.6c0-.1,16.6-.2,16.6-.2h15.1c13.5-.2,25.4-4.9,35.6-14.4,2-1.8,3.9-3.9,5.8-6,11.4-13.4,17.1-29.6,17-48.4V13.9c-.1,0-29.4-.2-29.4-.2v27.4c0,9.4-2.8,17.4-8.5,24.2-5.7,6.8-12.6,10.2-20.7,10.2h-31.6l-30,.3v8.1s-.3,19.6-.3,19.6v6.5c10-11.5,20.2-23,30.3-34.5Z"/>
      </g>
    </g>
  </g>
</svg>
);

export default LogoHeaderColor;