import React from 'react';

const IconXD = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300">
    <g>
      <g>
        <g>
          <g>
            <g>
              <g>
                <path className="fill-bordo" d="M55.5,7.3h189.1c28.7,0,51.8,23.2,51.8,51.8v181.8c0,28.7-23.2,51.8-51.8,51.8H55.5c-28.7,0-51.8-23.2-51.8-51.8V59.1C3.6,30.4,26.8,7.3,55.5,7.3Z"/>
              </g>
            </g>
          </g>
          <g>
            <g>
              <path className="fill-babypink" d="M157.6,82.3l-36.6,60.4,39,64c.2.5.4,1,.2,1.5s-.6.1-1.3.2h-27.9c-2,0-3.3-.1-4.1-1.3-2.6-5.1-5.2-10.1-7.8-15.2-2.6-5-5.4-10.1-8.3-15.4s-5.9-10.5-8.8-15.9h-.2c-2.6,5.2-5.4,10.5-8.2,15.7-2.8,5.2-5.6,10.5-8.3,15.6-2.8,5.1-5.6,10.4-8.4,15.4-.5,1.2-1.5,1.3-2.8,1.3h-26.8c-.5,0-.9.2-.9-.4-.1-.5,0-1,.2-1.3l37.9-62.2-37-62.6c-.4-.5-.5-1-.2-1.2.2-.4.7-.5,1.2-.5h27.7c.6,0,1.2.1,1.7.2.5.2.9.6,1.2,1.1,2.3,5.2,5,10.5,7.8,15.7,2.9,5.2,5.7,10.4,8.8,15.5,2.9,5.1,5.6,10.2,8.2,15.5h.2c2.6-5.4,5.2-10.6,7.9-15.7,2.7-5.1,5.5-10.2,8.3-15.4s5.5-10.4,8.2-15.4c.1-.5.4-1,.7-1.2.5-.2,1-.4,1.6-.2h25.7c.6-.1,1.2.2,1.3.9.1.1-.1.6-.4.9h0Z"/>
              <path className="fill-babypink" d="M213.9,211c-9,.1-18.1-1.7-26.2-5.5-7.7-3.5-14-9.4-18.4-16.6-4.5-7.4-6.7-16.7-6.7-27.8-.1-9,2.2-17.9,6.7-25.7,4.6-7.9,11.3-14.5,19.4-18.9,8.5-4.8,18.8-7.1,30.9-7.1s1.5,0,2.6.1c1.1.1,2.3.1,3.8.2v-38.6c0-.9.4-1.3,1.2-1.3h24.8c.6-.1,1.1.4,1.2.9v116.4c0,2.2.1,4.6.2,7.3.2,2.6.4,5,.5,7.1,0,.9-.4,1.6-1.2,2-6.3,2.7-13.1,4.6-19.9,5.9-6.2,1.1-12.4,1.7-18.8,1.7ZM225.9,186.6v-53.7c-1.1-.2-2.2-.5-3.3-.6-1.3-.1-2.7-.2-4-.2-4.8,0-9.5,1-13.8,3.2-4.1,2.1-7.7,5.1-10.4,9-2.7,3.9-4,9.1-4,15.5-.1,4.3.6,8.5,2.1,12.6,1.2,3.3,3,6.2,5.5,8.7,2.3,2.2,5.1,3.9,8.3,4.9,3.3,1.1,6.7,1.6,10.1,1.6s3.5-.1,5.1-.2c1.6.1,2.9-.1,4.4-.6Z"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default IconXD;