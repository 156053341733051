import React, { useState } from 'react';
import ThemeToggleColorSvg from './ThemeToggleColorSvg';
import ThemeToggleDarkSvg from './ThemeToggleDarkSvg';

const ThemeToggle = () => {
    const [isDarkTheme, setIsDarkTheme] = useState(false);

    const toggleTheme = () => {
        const htmlElement = document.documentElement;
        if (isDarkTheme) {
            htmlElement.setAttribute('data-theme', 'color');
        } else {
            htmlElement.setAttribute('data-theme', 'dark');
        }
        setIsDarkTheme(!isDarkTheme);
    };

    return (
        <div id='themeToggle' className='theme-toggler' onClick={toggleTheme}>
            {isDarkTheme ? <ThemeToggleColorSvg /> : <ThemeToggleDarkSvg />}
        </div>
    );
};

export default ThemeToggle;
