import React from 'react';

const SectionBottomSvgDesktop = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1609.6 792">
  <path className='cls-1 path-1' d="M2.1,0h1607.8c-361.1,102.1-622.1,120.6-799.1,116.5-71.4-1.6-221.5-9.1-338.7,25.2-105.6,30.9-135.1,81.2-139.3,89.1-34.2,62.3,60.3,81.1,20,133.6-37.7,49.2-128,42.7-161.5,92.4-36.9,54.6,54.8,87.4-9,118.5-40.6,19.8-96.9,15.8-135.1,42-22.1,15.1-21.1,28.7-22.1,46.2-1.3,24.9-6.8,60.6-25,105.9C.8,513,1.3,256.5,2.1,0Z"/>
  <path className='cls-1 path-2' d="M396.1,308c-1.4-5.3-15.6-63.1,20.3-103.7,35.4-40.1,104.9-47.3,160-8.8-18.3-.4-55.6,1.4-95.5,22.6-53.5,28.5-77.4,73.9-84.9,90h0Z"/>
  <path className='cls-1 path-3' d="M722.8,138.2c24.4,7,59.1,14.8,99.6,16.7,67.2,3.2,114.1-11.7,137.5-21-14.2,33.7-54.5,54.4-104.9,54.1-74.1-.5-128.5-46.6-132.2-49.7h0Z"/>
  <circle className='cls-1 cls-a' cx="413" cy="351.3" r="13.4"/>
  <circle className='cls-1 cls-b' cx="394.8" cy="397.7" r="11.5"/>
  <circle className='cls-1 cls-c' cx="87.6" cy="642.2" r="11.5"/>
  <circle className='cls-1 cls-d' cx="1000.3" cy="152.6" r="14" transform="translate(356.7 933.4) rotate(-59.2)"/>
  <path className='cls-1 path-4' d="M249.8,529.5c-12.8-23-9.4-51.4,7.9-69.7,15.1-16,38.6-21.9,60.4-15.3-7.9,2.2-31.8,10-49.7,33.8-15.8,20.9-18.1,42.6-18.6,51h0Z"/>
  <circle className='cls-1 cls-e' cx="388.7" cy="426.3" r="5.3"/>
  <circle className='cls-1 cls-f' cx="389.8" cy="370.1" r="5.3"/>
  <circle className='cls-1 cls-g' cx="686.7" cy="154.2" r="5.3" transform="translate(202.4 664.9) rotate(-59.2)"/>
  <circle className='cls-1 cls-h' cx="752.9" cy="195.6" r="5.3" transform="translate(199.1 742) rotate(-59.2)"/>
  <circle className='cls-1 cls-i' cx="714.9" cy="171.1" r="9.8" transform="translate(201.7 697.4) rotate(-59.2)"/>
  <circle className='cls-1 cls-a' cx="1032.4" cy="127.9" r="5.3" transform="translate(393.6 948.9) rotate(-59.2)"/>
</svg>
);

export default SectionBottomSvgDesktop;