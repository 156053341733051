import React from 'react';

const QuoteBgMobileDark = () => (
<svg xmlns='http://www.w3.org/2000/svg' version='1.1'  viewBox='0 0 612 545.4'>
<g>
    <g>
      <path className="svg-bg" d="M0,13c204,22.3,408,44.7,612,67v395L0,520V13Z"/>
      <g>
        <g>
          <circle className="svg-highlight" cx="48" cy="78.6" r="18.8"/>
          <path className="svg-highlight" d="M48,56.5c-12.2,0-22.2,9.9-22.2,22.2s9.9,22.2,22.2,22.2,22.2-9.9,22.2-22.2-9.9-22.2-22.2-22.2ZM48,94c-8.5,0-15.3-6.9-15.3-15.3s6.9-15.3,15.3-15.3,15.3,6.9,15.3,15.3-6.9,15.3-15.3,15.3Z"/>
        </g>
        <g>
          <circle className="svg-highlight" cx="106" cy="78.6" r="18.8"/>
          <path className="svg-highlight" d="M106,56.5c-12.2,0-22.2,9.9-22.2,22.2s9.9,22.2,22.2,22.2,22.2-9.9,22.2-22.2-9.9-22.2-22.2-22.2ZM106,94c-8.5,0-15.3-6.9-15.3-15.3s6.9-15.3,15.3-15.3,15.3,6.9,15.3,15.3-6.9,15.3-15.3,15.3Z"/>
        </g>
        <path className="svg-highlight" d="M29.3,76.9c-1.9,0-3.4,1.5-3.4,3.4,0,26.3,21.4,47.7,47.7,47.7s3.4-1.5,3.4-3.4-1.5-3.4-3.4-3.4c-22.6,0-40.9-18.4-40.9-40.9s-1.5-3.4-3.4-3.4Z"/>
        <path className="svg-highlight" d="M87.3,76.9c-1.9,0-3.4,1.5-3.4,3.4,0,26.3,21.4,47.7,47.7,47.7s3.4-1.5,3.4-3.4-1.5-3.4-3.4-3.4c-22.6,0-40.9-18.4-40.9-40.9s-1.5-3.4-3.4-3.4Z"/>
      </g>
    </g>
  </g>
</svg>
);

export default QuoteBgMobileDark;