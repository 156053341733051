import React from 'react';

const QuoteBgDesktop = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1063 432">
<g>
    <g>
      <path className="svg-bg" d="M0,22c354.3,8.4,708.6,16.9,1062.9,25.3v304.7c-354.3,15.3-708.6,30.7-1062.9,46V22Z"/>
      <g>
        <g>
          <circle className="svg-highlight" cx="66" cy="66.8" r="18.8"/>
          <path className="svg-highlight" d="M66,44.7c-12.2,0-22.2,9.9-22.2,22.2s9.9,22.2,22.2,22.2,22.2-9.9,22.2-22.2-9.9-22.2-22.2-22.2ZM66,82.2c-8.5,0-15.3-6.9-15.3-15.3s6.9-15.3,15.3-15.3,15.3,6.9,15.3,15.3-6.9,15.3-15.3,15.3Z"/>
        </g>
        <g>
          <circle className="svg-highlight" cx="124" cy="66.8" r="18.8"/>
          <path className="svg-highlight" d="M124,44.7c-12.2,0-22.2,9.9-22.2,22.2s9.9,22.2,22.2,22.2,22.2-9.9,22.2-22.2-9.9-22.2-22.2-22.2ZM124,82.2c-8.5,0-15.3-6.9-15.3-15.3s6.9-15.3,15.3-15.3,15.3,6.9,15.3,15.3-6.9,15.3-15.3,15.3Z"/>
        </g>
        <path className="svg-highlight" d="M47.3,65.1c-1.9,0-3.4,1.5-3.4,3.4,0,26.3,21.4,47.7,47.7,47.7s3.4-1.5,3.4-3.4-1.5-3.4-3.4-3.4c-22.6,0-40.9-18.4-40.9-40.9s-1.5-3.4-3.4-3.4Z"/>
        <path className="svg-highlight" d="M105.3,65.1c-1.9,0-3.4,1.5-3.4,3.4,0,26.3,21.4,47.7,47.7,47.7s3.4-1.5,3.4-3.4-1.5-3.4-3.4-3.4c-22.6,0-40.9-18.4-40.9-40.9s-1.5-3.4-3.4-3.4Z"/>
      </g>
    </g>
  </g>
</svg>
);

export default QuoteBgDesktop;