import React, { useEffect, useState } from 'react';
import SectionBottomSvgTablet from './snippets/SectionBottomSvgTablet';
import SectionBottomSvgMobile from './snippets/SectionBottomSvgMobile';
import SectionBottomSvgDesktop from './snippets/SectionBottomSvgDesktop';
import { handleResize, updateTheme } from './global/utils';

function SectionBottom() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme'));

  useEffect(() => {
    // Użycie funkcji handleResize z utils.js
    const cleanUpResize = handleResize(setScreenWidth);
    
    // Użycie updateTheme z utils.js
    const cleanUpTheme = updateTheme(setTheme);

    return () => {
      cleanUpResize(); // Usuwanie event listenera 'resize'
      cleanUpTheme();  // Rozłączanie observera
    };
  }, []);

  if (theme === 'dark') {
    return null; 
  }

  let componentToRender;
  if (screenWidth < 700) {
    componentToRender = <SectionBottomSvgMobile />;
  } else if (screenWidth >= 700 && screenWidth < 1500) {
    componentToRender = <SectionBottomSvgTablet />;
  } else {
    componentToRender = <SectionBottomSvgDesktop />;
  }

  return (
    <section className='section-bottom' id='SectionBottom'>
      {componentToRender}
    </section>
  );
}

export default SectionBottom;