import React, { useState } from 'react'; 

const AboutMeYou = () => {
    return (
        <div className='section-about-me__content__header-hl'>
            <div>
                <span>Y</span><span>O</span><span>U</span>
            </div>
        </div>
    );
};

export default AboutMeYou;
