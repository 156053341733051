import React from 'react';

const QuoteBgDesktop = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1063 432">
  <path className='elem-2' d="M54.1,52.5c50.2-58.9,265.4-5.7,481.9-14.1,0,0,311.2-13.2,417.4,19.4,3,.9,5.7,1.8,8.2,2.8,53.5,19.3,61,55.5,74.1,126.9,16.7,91.7,26.9,157.8-59.5,172.5-27.3,4.7-43.8.6-200.4,8.9-28.1,1.5-51,2.8-66,3.7-16.4.1-40.8,1.4-69.5,7.5-33,6.9-47.8,15.1-72.5,23.3-72.1,23.8-134.1,16.5-134.2,15.1-.1-1.2,45.1.6,98-22.7,18.5-8.2,33.1-17.2,43.5-24.5-56.3,1.8-139.7,3.8-241.5,4-131.9.3-198.4.3-230.1-7.2-41.9-10-74.2-29.6-82.2-171.5-3.8-67.7,3.7-98.1,17.3-121.9,0,0,6.8-11.9,15.6-22.2Z"/>
  <path className='elem-1 elem-1a' d="M1042.6,23.5h-36.3c-2.4,0-4.3,1.8-4.3,4.1v35.2c0,2.3,1.9,4.1,4.3,4.1h13.6c-.6,16.3-5.2,24.6-13.6,24.6s-4.3,1.8-4.3,4.1v16.7c0,2.3,1.9,4.1,4.3,4.1,6.6,0,12.6-1.4,17.7-4,5.1-2.6,9.4-6.4,12.9-11.3,3.4-4.7,5.9-10.4,7.6-16.9,1.6-6.3,2.4-13.5,2.4-21.3V27.6c0-2.3-1.9-4.1-4.3-4.1h0Z"/>
  <path className='elem-1 elem-1a' d="M983.3,23.5h-36.3c-2.4,0-4.3,1.8-4.3,4.1v35.2c0,2.3,1.9,4.1,4.3,4.1h13.4c-.6,16.3-5.1,24.6-13.4,24.6s-4.3,1.8-4.3,4.1v16.7c0,2.3,1.9,4.1,4.3,4.1,6.6,0,12.6-1.4,17.7-4,5.1-2.6,9.4-6.4,12.9-11.3,3.4-4.7,5.9-10.4,7.6-16.9,1.6-6.3,2.4-13.5,2.4-21.3V27.6c0-2.3-1.9-4.1-4.3-4.1h0Z"/>
  <path className='elem-1 elem-1b' d="M69.5,327.9v16.7c0,2.3-1.8,4.1-4.1,4.1-8.1,0-12.4,8.3-13,24.6h13c2.3,0,4.1,1.8,4.1,4.1v35.2c0,2.3-1.8,4.1-4.1,4.1H30.6c-2.3,0-4.1-1.8-4.1-4.1v-35.2c0-7.8.8-15,2.3-21.3,1.6-6.5,4-12.2,7.3-16.9,3.3-4.8,7.5-8.6,12.3-11.3,4.9-2.7,10.6-4,16.9-4s4.1,1.8,4.1,4.1h.1Z"/>
  <path className='elem-1 elem-1b' d="M122.3,323.8c-6.3,0-12,1.3-16.9,4-4.9,2.6-9,6.4-12.3,11.3-3.2,4.7-5.7,10.4-7.3,16.9-1.6,6.3-2.3,13.5-2.3,21.3v35.2c0,2.3,1.8,4.1,4.1,4.1h34.8c2.3,0,4.1-1.8,4.1-4.1v-35.2c0-2.3-1.8-4.1-4.1-4.1h-12.9c.6-16.3,4.9-24.6,12.9-24.6s4.1-1.8,4.1-4.1v-16.7c0-2.3-1.8-4.1-4.1-4.1h0Z"/>
</svg>
);

export default QuoteBgDesktop;