import React from 'react';

const IconWebDev = () => (
<svg id="iconWebDev" data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 173.66 127.64">
  <path d="m109.22,103.2c6.72.1,11.99,1.7,11.99,3.66v7.13c0,2.01-5.72,3.66-12.81,3.66H24.72c-7.09,0-12.72-1.64-12.72-3.66v-7.13c0-1.96,5.18-3.55,11.9-3.66" className="color-fill" strokeWidth="0"/>
  <path d="m93.79,99.86h23.59v-49.33c0-6.12,5.12-11.06,11.42-11.06h13.3v-15.71c0-10.68-8.97-19.34-20.04-19.34H24.51C13.43,4.42,4.42,13.08,4.42,23.76v56.76c0,10.68,9.02,19.34,20.09,19.34h28.18" fill="none" className='stroke-base' strokeMiterlimit="10" strokeWidth="8.84" id="iconWebDevPathAnimate1"/>
  <line x1="93.79" y1="99.86" x2="52.69" y2="99.86" fill="none" className='stroke-base' strokeMiterlimit="10" strokeWidth="8.84"/>
  <path d="m169.24,50.16v62.35c0,5.92-5.37,10.71-12.03,10.71h-27.87c-6.61,0-11.98-4.78-11.98-10.71v-62.35c0-5.92,5.37-10.71,11.98-10.71h27.87c6.66,0,12.03,4.78,12.03,10.71Z" fill="none" className='stroke-base' strokeMiterlimit="10" strokeWidth="8.84" id="iconWebDevPathAnimate2"/>
  <ellipse cx="74.37" cy="13.5" rx="2.47" ry="2.38" className="color-fill" strokeWidth="0"/>
  <ellipse cx="143.44" cy="114.33" rx="3.46" ry="3.34" className="color-fill" strokeWidth="0"/>
  <g>
    <rect x="19.15" y="78.95" width="7.51" height="7.25" rx=".39" ry=".39" className="color-fill" strokeWidth="0"/>
    <rect x="19.15" y="66.81" width="7.51" height="7.25" rx=".39" ry=".39" className="color-fill" strokeWidth="0"/>
  </g>
  <rect x="26.89" y="95.99" width="86.13" height="8.09" rx="3.02" ry="3.02" strokeWidth="0" className='fill-base'/>
</svg>
);

export default IconWebDev;