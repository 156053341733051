import React from 'react';

const IconUX = () => (
<svg id="iconUX" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.12 68.31">
  <path id="iconUXPathAnimate" d="m85.74,11.99v36.7c0,5.3-4.3,9.58-9.61,9.58H11.99c-5.3,0-9.61-4.28-9.61-9.58V11.99C2.38,6.68,6.68,2.38,11.99,2.38h64.14c5.3,0,9.61,4.3,9.61,9.61Z" fill="none" className='stroke-base' strokeMiterlimit="10" strokeWidth="4.76"/>
  <g>
    <rect x="10.62" y="15.75" width="33.09" height="19.58" rx=".89" ry=".89" fill="none" className="color-stroke" strokeMiterlimit="10" strokeWidth="2.38"/>
    <line x1="11.23" y1="17.04" x2="43.11" y2="34.65" fill="none" className="color-stroke" strokeMiterlimit="10" strokeWidth="2.38"/>
    <line x1="11.27" y1="34.35" x2="43.07" y2="17.07" fill="none" className="color-stroke" strokeMiterlimit="10" strokeWidth="2.38"/>
  </g>
  <rect x="10.62" y="42.91" width="33.09" height="5.36" rx=".25" ry=".25" fill="none" className="color-stroke" strokeMiterlimit="10" strokeWidth="2.38"/>
  <rect x="48.15" y="18.93" width="32.52" height="26.16" rx=".89" ry=".89" transform="translate(96.42 -32.4) rotate(90)" fill="none" className="color-stroke" strokeMiterlimit="10" strokeWidth="2.38"/>
  <path d="m72.96,60.54c4.56.06,8.13.91,8.13,1.97v3.84c0,1.08-3.88,1.97-8.69,1.97H15.65c-4.81,0-8.63-.89-8.63-1.97v-3.84c0-1.05,3.51-1.91,8.07-1.97" className="color-fill" strokeWidth="0"/>
  <rect x="73.6" y="61.09" width="1.82" height="1.32" rx=".22" ry=".22" className="color-fill" strokeWidth="0"/>
  <ellipse cx="44.06" cy="7.12" rx="1.28" ry="1.15" className="color-fill" strokeWidth="0"/>
</svg>
);

export default IconUX;