import React from 'react';

const SectionBottomSvgMobile = () => (
    <svg  xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 612 792'>
    <path className='cls-1 path-1' d='M.8,0h610.4c-137.1,102.1-236.2,120.6-303.4,116.5-27.1-1.6-84.1-9.1-128.6,25.2-40.1,30.9-51.3,81.2-52.9,89.1-13,62.3,22.9,81.1,7.6,133.6-14.3,49.2-48.6,42.7-61.3,92.4-14,54.6,20.8,87.4-3.4,118.5-15.4,19.8-36.8,15.8-51.3,42-8.4,15.1-8,28.7-8.4,46.2-.5,24.9-2.6,60.6-9.5,105.9C.3,513,.5,256.5.8,0Z'/>
    <path className='cls-1 path-2' d='M170.4,294c-1.8-5.2-20.7-61.6,11.8-105,32-42.8,100.7-55.7,158.8-21.8-18.3,1.1-55.3,5.9-93.3,30.3-51,32.8-71.1,80-77.3,96.6Z'/>
    <path className='cls-1 path-3' d='M412.9,130.8c21-1.6,50.2-6.4,82.4-20.2,53.4-23,86.1-58.7,101.9-78.8-2.1,44.5-28,84.4-67.5,104-58.1,28.8-113-2.8-116.8-5Z'/>
    <circle className='cls-1 cls-a' cx='176.7' cy='328.9' r='13.4'/>
    <circle className='cls-1 cls-b' cx='158.5' cy='375.3' r='11.5'/>
    <circle className='cls-1 cls-c' cx='48.2' cy='632.5' r='11.5'/>
    <circle className='cls-1 cls-d' cx='583.7' cy='134.4' r='13.4'/>
    <path className='cls-1 path-4' d='M113.7,523.2c-20-17.1-26.7-44.9-16.8-68.1,8.6-20.2,28.6-34,51.3-35.3-6.6,4.8-26.3,20.4-34.8,49-7.5,25.1-2.2,46.2.3,54.3Z'/>
    <circle className='cls-1 cls-e' cx='152.4' cy='403.9' r='5.3'/>
    <circle className='cls-1 cls-f' cx='153.5' cy='347.7' r='5.3'/>
    <circle className='cls-1 cls-g' cx='341' cy='135.9' r='5.3'/>
    <circle className='cls-1 cls-h' cx='438.1' cy='163.9' r='5.3'/>
    <circle className='cls-1 cls-i' cx='373.9' cy='135.9' r='9.8'/>
  </svg> 
);

export default SectionBottomSvgMobile;