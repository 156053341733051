// utils.js
export const handleResize = (setIsMobile) => {
    const resizeHandler = () => {
      setIsMobile(window.innerWidth < 900);
    };
  
    window.addEventListener('resize', resizeHandler);
  
    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  };
  
  export const updateTheme = (setTheme) => {
    const themeHandler = () => {
      const htmlElement = document.documentElement;
      setTheme(htmlElement.getAttribute('data-theme'));
    };
  
    // Observer to monitor changes to the 'data-theme' attribute
    const observer = new MutationObserver(() => {
      themeHandler();
    });
  
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });
  
    return () => observer.disconnect();
  };
  