import React, { useEffect, useState } from 'react';
import * as ScrollMagic from 'scrollmagic';
import { gsap } from 'gsap';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'; 
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { handleResize } from './global/utils';

import IconAI from './snippets/skillsIcons/iconAI';
import IconAsana from './snippets/skillsIcons/iconAsana';
import IconCss from './snippets/skillsIcons/iconCSS';
import IconHtml from './snippets/skillsIcons/iconHTML';
import IconJira from './snippets/skillsIcons/iconJira';
import IconJS from './snippets/skillsIcons/iconJS';
import IconLiquid from './snippets/skillsIcons/iconLiquid';
import IconNetsuite from './snippets/skillsIcons/iconNetsuite';
import IconPS from './snippets/skillsIcons/iconPS';
import IconReact from './snippets/skillsIcons/iconReact';
import IconSass from './snippets/skillsIcons/iconSass';
import IconSEO from './snippets/skillsIcons/iconSEO';
import IconShopify from './snippets/skillsIcons/iconShopify';
import IconVS from './snippets/skillsIcons/iconVS';
import IconXD from './snippets/skillsIcons/iconXD';
import IconMastercard from './snippets/skillsIcons/iconMastercard';
import IconKlaviyo from './snippets/skillsIcons/iconKlaviyo';
import IconGA from './snippets/skillsIcons/iconGA';

gsap.registerPlugin(ScrollTrigger);
ScrollMagicPluginGsap(ScrollMagic, gsap);

function SectionSkills() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    const cleanUpResize = handleResize(setIsMobile, 900);  

    return () => {
      cleanUpResize();
    };
  }, []);

  useEffect(() => {
    const tooltipObjects = document.querySelectorAll('.data-tooltip-object');

    tooltipObjects.forEach((obj) => {
      const tooltipText = obj.getAttribute('data-tooltip-txt');

      const showTooltip = () => {
        const tooltipHTML = `
          <div class="obj-tooltip">
            <div class="obj-tooltip__bubble"></div>
            <div class="obj-tooltip__bubble"></div>
            <div class="obj-tooltip__bubble"></div>
            <div class="obj-tooltip__bubble cloud">${tooltipText}</div>
          </div>
        `;
        obj.insertAdjacentHTML('beforeend', tooltipHTML);
      };

      const hideTooltip = () => {
        const tooltip = obj.querySelector('.obj-tooltip');
        if (tooltip) {
          tooltip.remove();
        }
      };

      obj.addEventListener('mouseenter', showTooltip);
      obj.addEventListener('mouseleave', hideTooltip);
    });

    return () => {
      tooltipObjects.forEach((obj) => {
        obj.removeEventListener('mouseenter', showTooltip);
        obj.removeEventListener('mouseleave', hideTooltip);
      });
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      const throttled = (delay, fn) => {
        let lastCall = 0;
        return function (...args) {
          const now = new Date().getTime();
          if (now - lastCall < delay) {
            return;
          }
          lastCall = now;
          return fn(...args);
        };
      };

      const mouseMoveHandler2 = (e, movableElements) => {
        movableElements.forEach((movableElement) => {
          const shiftValue = movableElement.getAttribute('data-move');
          const moveX = (e.clientX * shiftValue) / 250;
          const moveY = (e.clientY * shiftValue) / 250;

          gsap.to(movableElement, { x: moveX, y: moveY, duration: 1 });
        });
      };

      const tHandler = throttled(200, (e) => {
        const allMovableElementsWrappers = document.querySelectorAll('.section-skills__slide-skills');
        allMovableElementsWrappers.forEach((wrapper) => {
          const movableElements = wrapper.querySelectorAll('.section-skills__slide-skills__element');
          mouseMoveHandler2(e, movableElements);
        });
      });

      const allMovableElementsWrappers = document.querySelectorAll('.section-skills__slide-skills');

      allMovableElementsWrappers.forEach((wrapper) => {
        wrapper.onmousemove = tHandler;
      });

      return () => {
        allMovableElementsWrappers.forEach((wrapper) => {
          wrapper.onmousemove = null;
        });
      };
    }
  }, [isMobile]);


  useEffect(() => { 
    const elements = document.querySelectorAll('.section-skills__slide-skills__element');

    elements.forEach((element) => {
      const parentSlide = element.closest('.section-skills__slide');
      const triggerElement = parentSlide.querySelector('.section-skills__slide-title-main');
  
  
      ScrollTrigger.create({
        trigger: triggerElement, 
        start: isMobile ? 'top bottom' : 'top bottom', 
        onEnter: () => {
          gsap.to(element, { 
            onComplete: () => { 
              element.classList.add('visibleobj');
            }
          });
        },
        once: true  
      });
    });
  }, [isMobile]);


  return (
    <section className='section-skills' id='sectionSkills'>
          <div className="section-skills__header">
                <h2 className='section-skills__header-h2 header-h2'>My <span>S</span><span>K</span><span>I</span><span>L</span><span>L</span><span>S</span>
                </h2>
            </div>
        <div id='pinContainer'>
         

            <div id='sectionSkillsSlides'>
                <div className='section-skills__slide__container'> 
                <div className='section-skills__slide section-skills__slide-code'>
                    <div className='section-skills__slide-title'>
                        <p className='section-skills__slide-title-bg'>Code</p>
                        <h3 className='section-skills__slide-title-main'>Code</h3>
                    </div>
                    <div className='section-skills__slide-description'>
                        <p className='section-skills__slide-description-text'>I professionally work with code over 5 years using the most popular front-end technologies and Shopify Liquid to create best perfoming e-commerce stores.</p>
                    </div>
                    <div className='section-skills__slide-skills section-skills__slide-skills-code'>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='HTML5' data-move='-9'><IconHtml /></div>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='CSS3' data-move='5'><IconCss /></div>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='Javascript' data-move='-6'><IconJS /></div>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='SASS' data-move='7'><IconSass /></div>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='Shopify Liquid' data-move='-4'><IconLiquid /></div>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='React' data-move='4'><IconReact /></div>
                        <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-code__element data-tooltip-object' data-tooltip-txt='Visual Studio' data-move='-8'><IconVS /></div>
                    </div>
                </div>
                 </div>

                 <div className='section-skills__slide__container'> 
                <div className='section-skills__slide section-skills__slide-design'>
                <div className='section-skills__slide-title'>
                    <p className='section-skills__slide-title-bg'>Design</p>
                    <h3 className='section-skills__slide-title-main'>Design</h3>
                </div>
                <div className='section-skills__slide-description'>
                    <p className='section-skills__slide-description-text'>I use design tools to work on user experience prototypes, user interfaces and photo retouch. I specialize in raster graphic but I also work with vector objects.</p>
                </div>
                <div className='section-skills__slide-skills section-skills__slide-skills-design'>

                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-design__element data-tooltip-object' data-tooltip-txt='Adobe Photoshop' data-move='-4'><IconPS /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-design__element data-tooltip-object' data-tooltip-txt='Adobe Illustrator' data-move='7'><IconAI /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-design__element data-tooltip-object' data-tooltip-txt='Adobe XD' data-move='3'><IconXD /></div>
                </div>
              </div>
              </div>

              <div className='section-skills__slide__container'> 
              <div className='section-skills__slide section-skills__slide-ecommerce'>
                <div className='section-skills__slide-title'>
                    <p className='section-skills__slide-title-bg'>E-commerce</p>
                    <h3 className='section-skills__slide-title-main'>E-commerce</h3>
                </div>
                <div className='section-skills__slide-description'>
                    <p className='section-skills__slide-description-text'>I specialize in developing and maintaining stores using Shopify platform, advanced personalization, conducting UX testing and audits, and improving SEO.</p>
                </div>
                <div className='section-skills__slide-skills section-skills__slide-skills-ecommerce'>

                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-ecommerce__element data-tooltip-object' data-tooltip-txt='Shopify' data-move='-7'><IconShopify /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-ecommerce__element data-tooltip-object' data-tooltip-txt='Klayvio' data-move='5'><IconKlaviyo /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-ecommerce__element data-tooltip-object' data-tooltip-txt='Search Engine Optimization' data-move='-3'><IconSEO /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-ecommerce__element data-tooltip-object' data-tooltip-txt='Dynamic Yield by MasterCard' data-move='7'><IconMastercard /></div>
                </div>
                </div>
              </div>

              <div className='section-skills__slide__container'> 
              <div className='section-skills__slide section-skills__slide-pm'>
                <div className='section-skills__slide-title'>
                    <p className='section-skills__slide-title-bg'>PM & Data</p>
                    <h3 className='section-skills__slide-title-main'>PM & Data</h3>
                </div>
                <div className='section-skills__slide-description'>
                    <p className='section-skills__slide-description-text'>I have experience in managing IT projects from scoping phase to final development. I also work with data organization and analysis.</p>
                </div>
                <div className='section-skills__slide-skills section-skills__slide-skills-pm'>

                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-pm__element data-tooltip-object' data-tooltip-txt='Jira' data-move='-7'><IconJira /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-pm__element data-tooltip-object' data-tooltip-txt='Asana' data-move='-5'><IconAsana /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-pm__element data-tooltip-object' data-tooltip-txt='Google Analytics' data-move='-2'><IconGA /></div>
                <div className='section-skills__slide-skills__element hiddenobj section-skills__slide-skills-pm__element data-tooltip-object' data-tooltip-txt='Netsuite' data-move='6'><IconNetsuite /></div>             
                </div>
                </div>
              </div>

            </div> 
        </div>
    </section>
);
}

export default SectionSkills;