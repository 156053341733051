import React from 'react';

const IconKlaviyo = () => (
<svg version="1.1" viewBox="0 0 67.8 45.4" className="section-skills__skill-icon">
 <g>
  <path d="M67.8,45.4H0V0h67.8L53.6,22.7L67.8,45.4L67.8,45.4z">
  </path>
 </g>
</svg>
);

export default IconKlaviyo;