import React, { useEffect, useState } from 'react';
import gsap from "gsap";
import ScrollMagic from "scrollmagic";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import anime from "animejs";
import QuoteBgMobile from './snippets/QuoteBgMobile';
import QuoteBgDesktop from './snippets/QuoteBgDesktop';
import QuoteBgMobileDark from './snippets/QuoteBgMobileDark';
import QuoteBgDesktopDark from './snippets/QuoteBgDesktopDark';
import { handleResize, updateTheme } from './global/utils';

ScrollMagicPluginGsap(ScrollMagic, gsap);

function SectionQuote() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme'));

  useEffect(() => {
    // Handle resize and theme update cleanup
    const cleanUpResize = handleResize(setIsMobile);
    const cleanUpTheme = updateTheme(setTheme);

    // Initialize ScrollMagic and Anime.js timeline
    const controller = new ScrollMagic.Controller();
    let tl1 = anime.timeline({ autoplay: false });

    // Define animations
    let s1a1 = {
      targets: '#sectionQuote .section-quote__background svg .elem-1a',
      opacity: 1,
      translateX: { value: [250, 0], duration: 400 },
      rotate: { value: [90, 0], duration: 1200, easing: 'easeInOutSine' },
      scale: { value: [2, 1], duration: 1100, delay: 800, easing: 'easeInOutQuart' },
      duration: 800,
      delay: 0,
      easing: 'easeInOutSine'
    };

    let s1a2 = {
      targets: '#sectionQuote .section-quote__background svg .elem-1b',
      opacity: 1,
      translateY: { value: [-500, 0], duration: 400 },
      translateX: { value: [-500, 0], duration: 400 },
      rotate: { value: [90, 0], duration: 1200, easing: 'easeInOutSine' },
      duration: 800,
      delay: 0,
      easing: 'easeInOutSine'
    };

    let s1a3 = {
      targets: '#sectionQuote .section-quote__background svg .elem-2',
      opacity: [0, 1],
      translateX: { value: ['-100vw', '0vw'], duration: 800 },
      translateY: { value: [-100, 0], duration: 800 },
      easing: 'easeInOutSine',
      duration: 1000
    };

    // Add animations to the timeline
    tl1.add(s1a3).add(s1a1, '-=1600').add(s1a2, '-=1600');

    // Set up ScrollMagic scene
    const scene1 = new ScrollMagic.Scene({
      triggerElement: "#sectionQuote",
      triggerHook: 0.5,
      reverse: false
    })
    .on("enter", () => tl1.play())
    .setClassToggle(".section-quote__content__text > p", "animate__lightSpeedInLeft")
    .addTo(controller);

    // Clean up ScrollMagic controller on component unmount
    return () => {
      scene1.destroy(true);
      controller.destroy(true);
      cleanUpResize();
      cleanUpTheme();
    };
  }, []);

  const renderBackground = () => {
    if (theme === 'color') {
      return isMobile ? <QuoteBgMobile /> : <QuoteBgDesktop />;
    } else if (theme === 'dark') {
      return isMobile ? <QuoteBgMobileDark /> : <QuoteBgDesktopDark />;
    }
    return null;
  };

  return (
    <section className='section-quote' id='sectionQuote'>
      <div className='section-quote__waves'>
        <div className='section-quote__waves-wave'></div>
        <div className='section-quote__waves-wave'></div>
        <div className='section-quote__waves-wave'></div>
      </div>
      <div id='trigger1' className='spacer s0'></div>
      <div className='section-quote__background'>
        {renderBackground()}
        <div className='section-quote__content'>
          <div className='section-quote__content__text'>
            <p className='section-quote__content__text__quote'>
              I never did a day's work in my life.<br />It was all fun.
            </p>
            <p className='section-quote__content__text__author'>Thomas A. Edison</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SectionQuote;
