import React from 'react';

const QuoteBgMobile = () => (
<svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 612 545.4'>
 <path className='elem-2' d='M31.5,73.1c33.8-32.8,114.3-33.9,274-34.7,196.9-.9,225.8,15.3,241.9,28.5,30.4,24.8,34.7,71.3,42.1,163.2,9.5,117.9,15.3,202.8-33.8,221.7-15.5,6-24.9.8-113.9,11.4-16,1.9-29,3.6-37.5,4.8-19.2,12.5-46,27.3-80.7,39.5-25.1,8.8-48.4,14.2-68,17.5,11.4-3.9,30.2-11.8,47.4-27.2,12.9-11.6,20.4-23.2,24.7-31.5-32,2.3-79.4,4.9-137.3,5.2-75,.4-112.8.4-130.8-9.3-23.8-12.8-42.2-38-46.7-220.5-3.5-141.3,6.7-157.1,18.5-168.6Z'/>
 <path className='elem-1 elem-1a' d='M591.6,23.5h-36.3c-2.4,0-4.3,1.8-4.3,4.1v35.2c0,2.3,1.9,4.1,4.3,4.1h13.6c-.6,16.3-5.2,24.6-13.6,24.6s-4.3,1.8-4.3,4.1v16.7c0,2.3,1.9,4.1,4.3,4.1,6.6,0,12.6-1.4,17.7-4,5.1-2.6,9.4-6.4,12.9-11.3,3.4-4.7,5.9-10.4,7.6-16.9,1.6-6.3,2.4-13.5,2.4-21.3V27.6c0-2.3-1.9-4.1-4.3-4.1Z'/>
 <path className='elem-1 elem-1a' d='M532.3,23.5h-36.3c-2.4,0-4.3,1.8-4.3,4.1v35.2c0,2.3,1.9,4.1,4.3,4.1h13.4c-.6,16.3-5.1,24.6-13.4,24.6s-4.3,1.8-4.3,4.1v16.7c0,2.3,1.9,4.1,4.3,4.1,6.6,0,12.6-1.4,17.7-4,5.1-2.6,9.4-6.4,12.9-11.3,3.4-4.7,5.9-10.4,7.6-16.9,1.6-6.3,2.4-13.5,2.4-21.3V27.6c0-2.3-1.9-4.1-4.3-4.1Z'/>
 <path className='elem-1 elem-1b' d='M55.2,404.9v16.7c0,2.3-1.8,4.1-4.1,4.1-8.1,0-12.4,8.3-13,24.6h13c2.3,0,4.1,1.8,4.1,4.1v35.2c0,2.3-1.8,4.1-4.1,4.1H16.3c-2.3,0-4.1-1.8-4.1-4.1v-35.2c0-7.8.8-15,2.3-21.3,1.6-6.5,4-12.2,7.3-16.9,3.3-4.8,7.5-8.6,12.3-11.3,4.9-2.7,10.6-4,16.9-4,2.3,0,4.1,1.8,4.1,4.1Z'/>
 <path className='elem-1 elem-1b' d='M108,400.8c-6.3,0-12,1.3-16.9,4-4.9,2.6-9,6.4-12.3,11.3-3.2,4.7-5.7,10.4-7.3,16.9-1.6,6.3-2.3,13.5-2.3,21.3v35.2c0,2.3,1.8,4.1,4.1,4.1h34.8c2.3,0,4.1-1.8,4.1-4.1v-35.2c0-2.3-1.8-4.1-4.1-4.1h-12.9c.6-16.3,4.9-24.6,12.9-24.6s4.1-1.8,4.1-4.1v-16.7c0-2.3-1.8-4.1-4.1-4.1Z'/>
</svg>
);

export default QuoteBgMobile;