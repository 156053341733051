// PageHeader.js
import React, { useEffect, useState } from 'react';
import { updateTheme } from './global/utils';
import LogoHeaderColor from './snippets/LogoHeaderColor';
import LogoHeaderDark from './snippets/LogoHeaderDark';

import ThreeLetterGltf from './snippets/ThreeLetterGltf'; 
import ThreeMoon from './snippets/ThreeMoon';
 
function PageHeader() {
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme'));

  useEffect(() => {
    const cleanUpTheme = updateTheme(setTheme);

    return () => {
      cleanUpTheme();
      controller.destroy(true);
    };
  }, []);

  const render3d = () => {
    if (theme === 'dark') {
      return <ThreeMoon />;
    } return <ThreeLetterGltf />;
  };

  const renderLogo = () => {
    if (theme === 'dark') {
      return <LogoHeaderDark />;
    } return <LogoHeaderColor />;
  };


  return (
    <div id="welcome" className="welcome react-container">
      <div className="welcome-canvas">  
      {render3d()}
      </div>
    <div className="welcome__dark-sky__object1"></div>
    <div className="welcome__dark-sky__object2"></div>
    <div className="welcome__dark-sky__object3"></div> 
      <div className="welcome__content">
        <p className="welcome__preheader animate__backInLeft">Hey, I'm</p>
        <h1 className="welcome__h1">Klaudia Tuğul</h1>
        {renderLogo()}
        <h2 className="welcome__paragraph animate__bounceInLeft">UX Designer, Web Developer & E-commerce Expert</h2>
        </div>
  </div>
  );
}

export default PageHeader;
