import React from 'react';

const IconHtml = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300"> 
  <g>
    <g>
      <path className="fill-black" d="M66.9,7.3h13v12.8h11.9V7.3h13v38.9h-13v-13h-11.8v13h-13.1M121.8,20.2h-11.4V7.3h35.9v13h-11.5v25.9h-13M152,7.3h13.6l8.3,13.7,8.3-13.7h13.6v38.9h-13v-19.3l-9.1,14-9.1-14v19.3h-12.7M202.2,7.3h13v26h18.4v12.8h-31.3"/>
      <path className="fill-orange" d="M66.4,272.5L47.8,63.9h204.3l-18.6,208.5-83.8,23.2"/>
      <path className="fill-orange2" d="M150,277.9V81h83.5l-15.9,178"/>
      <path className="fill-darkwhite " d="M85.8,106.6h64.2v25.6h-36.2l2.4,26.2h33.8v25.5h-57.2M93.9,196.7h25.7l1.8,20.4,28.6,7.7v26.7l-52.5-14.6"/>
      <path className="fill-white" d="M214,106.6h-64.1v25.6h61.7M209.3,158.3h-59.4v25.6h31.5l-3,33.2-28.6,7.7v26.6l52.4-14.5"/>
    </g>
  </g>
</svg>
);

export default IconHtml;