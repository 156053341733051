import React from 'react';

const IconJS = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300"> 
  <g>
    <g id="Layer_1">
      <rect className="fill-yellow" x="8.2" y="8.2" width="283.7" height="283.7"/>
      <path className="fill-black" d="M198.7,229.8c5.7,9.3,13.1,16.2,26.3,16.2s18.1-5.5,18.1-13.1-7.2-12.4-19.4-17.7l-6.7-2.9c-19.2-8.2-32-18.5-32-40.2s15.2-35.2,39-35.2,29.1,5.9,37.9,21.3l-20.8,13.3c-4.6-8.2-9.5-11.4-17.2-11.4s-12.8,5-12.8,11.4,5,11.2,16.4,16.2l6.7,2.9c22.6,9.7,35.4,19.6,35.4,41.9s-18.9,37.1-44.2,37.1-40.7-11.8-48.6-27.3l21.7-12.6ZM104.6,232.1c4.2,7.4,8,13.7,17.2,13.7s14.3-3.4,14.3-16.8v-90.6h26.7v91c0,27.6-16.2,40.2-39.8,40.2s-33.7-11-40-24.3l21.7-13.1Z"/>
    </g>
  </g>
</svg>
);

export default IconJS;