import React, { useEffect, useState } from 'react';
import NavComponent from './snippets/NavComponent';
import ThemeToggle from './snippets/ThemeToggle';

import { handleResize, updateTheme } from './global/utils';

function PageMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
        const navContainer = document.querySelector('#nav-container');
        const menuIconContainer = document.querySelector('.menu-icon');
        if (navContainer) {
            navContainer.classList.toggle('nav-open');
            menuIconContainer.classList.toggle('close'); 
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };
  
        window.addEventListener('resize', handleResize);
  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 

    return (
        <header id='header' className='react-container'> 
        <div id='nav-container'>
            {isMobile ? <ThemeToggle /> : <></> }
            {isMobile ? 
            <div className='nav-but-wrap'>
                <div className='menu-icon' onClick={handleMenuClick}>
                    <span className='menu-icon__line menu-icon__line1'></span>
                    <span className='menu-icon__line menu-icon__line2'></span>
                    <span className='menu-icon__line menu-icon__line3'></span>
                </div>
            </div>
            : <div className='nav-desktop'>
              <NavComponent />  
              </div>
            }
            {isMenuOpen && (
                <div className='nav'>
                    <div className='nav__content'>
                        <NavComponent />
                    </div>
                </div>
            )}
        </div>
        </header>
    );
}

export default PageMenu;
