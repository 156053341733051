import React from 'react';

const IconBranding = () => (
<svg id="iconBranding" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 233.98 169.86">
  <g>
    <circle cx="70.63" cy="30.88" r="24.8" className="color-fill stroke-base" strokeMiterlimit="10" strokeWidth="12.16"/>
    <circle cx="165.98" cy="30.88" r="24.8" className="color-fill stroke-base" strokeMiterlimit="10" strokeWidth="12.16"/>
    <path className='stroke-base' d="m6.14,169.86v-32.65c-1.56-32.78,28.74-57.89,68.48-56.86,20.36.53,36.59,13.32,41.82,12.89" fill="none" strokeMiterlimit="10" strokeWidth="12.16" id="iconBrandPathAnimate1"/>
    <path className='stroke-base' d="m141.11,137.06c-.73,17.94-10.21,26.75-24.08,26.39-9.24-.3-23.96-5.65-23.71-29.97,0-17.15,9.36-29.97,23.77-38.43,3.59,2.07,25.29,9.91,24.02,42.01Z" fill="none" strokeMiterlimit="10" strokeWidth="12.16" id="iconBrandPathAnimate2"/>
    <path className='stroke-base' d="m115.94,93.23c5.23.43,23.06-12.36,43.42-12.89,39.75-1.03,70.05,24.08,68.48,56.86v32.65" fill="none" strokeMiterlimit="10" strokeWidth="12.16" id="iconBrandPathAnimate3"/>
  </g>
  <rect x="113.84" y="87.14" width="4.68" height="1.96" rx=".98" ry=".98" className='fill-base' strokeWidth="0"/>
</svg>
);

export default IconBranding;