import React, { useEffect, useState } from 'react';
import ScrollMagic from "scrollmagic"; 
import { gsap } from "gsap";  
import { ScrollTrigger } from "gsap/ScrollTrigger";  
import IconEcom from './snippets/IconEcom';
import IconUX from './snippets/IconUX';
import IconWebDev from './snippets/IconWebDev';
import IconBranding from './snippets/IconBranding';

import { handleResize, updateTheme } from './global/utils';

function WhatIDo() {
  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme'));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    const updateTheme = () => {
      const htmlElement = document.documentElement;
      setTheme(htmlElement.getAttribute('data-theme'));
    };
 
    const observer = new MutationObserver(() => {
      updateTheme();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-theme'],
    });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      observer.disconnect();
    };
  }, []);

  useEffect(() => { 
    const controller = new ScrollMagic.Controller();
 
    new ScrollMagic.Scene({
      triggerElement: '.section-what-i-do__header',
      triggerHook: 1,
      reverse: false, 
    })
      .setClassToggle('.section-what-i-do__header-h2', 'animate__backInLeft')
      .addTo(controller);
      
    gsap.registerPlugin(ScrollTrigger)

 
    const tl = gsap.timeline({
      scrollTrigger: {
          trigger: '.section-what-i-do__content-section',
          endTrigger: ".section-what-i-do__content-element-4",
          pin: true, 
          start: 'top top',
          end: 'bottom 0px',
          scrub: 1,
          ease: 'linear',
          autoAlpha: 0, 
        }
    })
 
    tl.to('.section-what-i-do__content-element-1', {
      top: 0, 
      opacity: 1, 
    })
    tl.to('.section-what-i-do__content-element-2', {
      top: 60, 
      opacity: 1,  
    })
    tl.to('.section-what-i-do__content-element-3', {
      top: 120, 
      opacity: 1,  
    })
    tl.to('.section-what-i-do__content-element-4', {
      top: 180, 
      opacity: 1,
    })
    tl.to('.section-what-i-do__final', {
      top: 460, 
      opacity: 1,
    })

    return () => {
      controller.destroy();
    };
  }, []);

  const handleScrollUp = () => {
    window.scrollBy({ top: -window.innerHeight / 2, behavior: 'smooth' });
  };

  return (
    <section className='section-what-i-do' id='whatIDo'>
      <div className="section-what-i-do__content-section-wrapper" id='whatIDoWrapper'>
        <div className="section-what-i-do__content-section"> 
          <div className="section-what-i-do__content-container">
            <div className="section-what-i-do__header">
              <h2 className='section-what-i-do__header-h2 header-h2 animate__animated'>See what <span className='mr-small'>I</span><span>D</span><span>O</span></h2>
            </div>
            <div className="section-what-i-do__content-elements">
              <div className="section-what-i-do__content-element section-what-i-do__content-element-1">
                <h3 className='section-what-i-do__content-element-header' onClick={handleScrollUp}>E-commerce</h3>
                <div className="section-what-i-do__content-element__text">
                
                  <p className='section-what-i-do__content-element__text-paragraph'>I specialize in launching and optimizing online stores to maximize sales and customer satisfaction. I have successfully launched 10+ online stores. My expertise in data analysis, personalization, and UX tools has boosted the revenue.</p><span className='section-what-i-do__content-element__text-icon what-i-do-icon'><IconEcom /></span>
                </div>
              </div>
              <div className="section-what-i-do__content-element section-what-i-do__content-element-2">
                <h3 className='section-what-i-do__content-element-header' onClick={handleScrollUp}>UX Design</h3>
                <div className="section-what-i-do__content-element__text">
                
                  <p className='section-what-i-do__content-element__text-paragraph'>I create intuitive user journeys and engaging UI designs for both mobile and desktop applications. Leveraging the latest trends, I craft prototypes that resonate with users and conduct comprehensive UX audits for online stores and websites.</p><span className='section-what-i-do__content-element__text-icon what-i-do-icon'><IconUX /></span>
                </div>
              </div>
              <div className="section-what-i-do__content-element section-what-i-do__content-element-3">
                <h3 className='section-what-i-do__content-element-header' onClick={handleScrollUp}>Web Development</h3>
                <div className="section-what-i-do__content-element__text">
                 
                  <p className='section-what-i-do__content-element__text-paragraph'>Specializing in Shopify store templates, I focus on optimizing speed and SEO efficiency to ensure top performance. I also create custom portfolio websites for individuals and businesses, enhancing their brand visibility and conversions.</p><span className='section-what-i-do__content-element__text-icon what-i-do-icon'><IconWebDev /></span>
                </div>
              </div>
              <div className="section-what-i-do__content-element section-what-i-do__content-element-4">
                <h3 className='section-what-i-do__content-element-header' onClick={handleScrollUp}>Branding</h3>
                <div className="section-what-i-do__content-element__text">
                
                  <p className='section-what-i-do__content-element__text-paragraph'>I provide in-depth analysis of companies' online branding, communication, and consistency, from store audits to social media strategies, that have led to increased brand recognition and growth in social media engagement. </p><span className='section-what-i-do__content-element__text-icon what-i-do-icon'><IconBranding /></span>
                </div>
              </div>
              <div className="section-what-i-do__final"></div>
            </div>   
          </div>
        </div> 
      </div> 
    </section>
  );
}

export default WhatIDo;
