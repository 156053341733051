import React from 'react';

const SectionBottomSvgTablet = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1085.4 792">
    <path className='cls-1 path-1' d="M.4,0h1084c-243.5,102.1-419.5,120.6-538.8,116.5-48.1-1.6-149.3-9.1-228.4,25.2-71.2,30.9-91.1,81.2-93.9,89.1-23.1,62.3,40.7,81.1,13.5,133.6-25.4,49.2-86.3,42.7-108.9,92.4-24.9,54.6,36.9,87.4-6,118.5-27.3,19.8-65.4,15.8-91.1,42-14.9,15.1-14.2,28.7-14.9,46.2-.9,24.9-4.6,60.6-16.9,105.9C-.5,513-.1,256.5.4,0Z"/>
    <path className='cls-1 path-2'  d="M291.8,307.3c-1.8-5.2-20.7-61.6,11.8-105,32-42.8,100.7-55.7,158.8-21.8-18.3,1.1-55.3,5.9-93.3,30.3-51,32.8-71.1,80-77.3,96.6h0Z"/>
    <path className='cls-1 path-3'  d="M535.5,141.5c25.1,4.3,60.4,8.3,100.8,5.9,67.1-4.1,112.2-23.9,134.4-35.8-10.5,35-48.3,60-98.4,65.1-73.7,7.5-132.7-32.4-136.8-35.2h0Z"/>
    <circle className='cls-1 cls-a' cx="298.1" cy="342.2" r="13.4"/>
    <circle className='cls-1 cls-b' cx="279.9" cy="388.6" r="11.5"/>
    <circle className='cls-1 cls-c' cx="80.9" cy="634.3" r="11.5"/>
    <circle className='cls-1 cls-d' cx="812.9" cy="125.8" r="14" transform="translate(359.9 812.4) rotate(-65.4)"/>
    <path className='cls-1 path-4' d="M182.1,536.5c-20-17.1-26.7-44.9-16.8-68.1,8.6-20.2,28.6-34,51.3-35.3-6.6,4.8-26.3,20.4-34.8,49-7.5,25.1-2.2,46.2.3,54.3h0Z"/>
    <path className='cls-1 path-4' d="M182.1,536.5c-20-17.1-26.7-44.9-16.8-68.1,8.6-20.2,28.6-34,51.3-35.3-6.6,4.8-26.3,20.4-34.8,49-7.5,25.1-2.2,46.2.3,54.3h0Z"/>
    <circle className='cls-1 cls-e' cx="273.8" cy="417.2" r="5.3"/>
    <circle className='cls-1 cls-f' cx="274.9" cy="361" r="5.3"/>
    <circle className='cls-1 cls-g' cx="501.4" cy="161.3" r="5.3" transform="translate(145.8 549.9) rotate(-65.4)"/>
    <circle className='cls-1 cls-h' cx="571.7" cy="195.3" r="5.3" transform="translate(155.9 633.6) rotate(-65.4)"/>
    <circle className='cls-1 cls-i' cx="531.3" cy="175" r="9.8" transform="translate(150.8 585.1) rotate(-65.4)"/>
    <circle className='cls-1 cls-a' cx="842.2" cy="97.8" r="5.3" transform="translate(402.4 822.7) rotate(-65.4)"/>
  </svg>
);

export default SectionBottomSvgTablet;