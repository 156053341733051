import React from 'react';

const IconAI = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300" className="section-skills__skill-icon">
  <g>
    <g>
      <path className="fill-darkred" d="M55.7,7.6h188.6c28.6,0,51.7,23.1,51.7,51.7v181.3c0,28.6-23.1,51.7-51.7,51.7H55.7c-28.6,0-51.7-23.1-51.7-51.7V59.4C4,30.8,27.1,7.6,55.7,7.6Z"/>
      <path className="fill-orange" d="M145.1,178h-45.3l-9.2,28.6c-.2,1.1-1.2,1.8-2.3,1.7h-22.9c-1.3,0-1.7-.7-1.3-2.2l39.2-112.8c.4-1.2.7-2.6,1.2-4,.5-2.6.7-5.2.7-7.9-.1-.6.4-1.2,1-1.3h31.5c1,0,1.5.4,1.6,1l44.4,125.3c.4,1.3,0,1.9-1.2,1.9h-25.4c-.9.1-1.7-.5-1.9-1.3l-10-29ZM106.9,153.3h30.9c-.7-2.6-1.7-5.6-2.8-8.8-1.1-3.3-2.2-6.8-3.3-10.5-1.2-3.8-2.3-7.4-3.5-11.2s-2.3-7.3-3.3-10.8c-1-3.4-1.8-6.6-2.7-9.5h-.2c-1.1,5.2-2.4,10.5-4.1,15.7-1.8,5.8-3.6,11.9-5.6,18-1.7,6.2-3.5,11.9-5.4,17ZM210.8,101.3c-4,.1-7.9-1.5-10.8-4.3-2.8-3-4.3-7.1-4.1-11.2-.1-4.1,1.5-8,4.4-10.8s6.8-4.3,10.8-4.3,8.4,1.5,11.1,4.3c2.7,2.9,4.1,6.8,4,10.8.1,4.1-1.3,8.2-4.3,11.2-2.8,2.9-6.9,4.5-11.1,4.3h0ZM197.2,206.9v-93.7c0-1.2.5-1.7,1.6-1.7h24.1c1.1,0,1.6.6,1.6,1.7v93.7c0,1.3-.5,1.9-1.6,1.9h-23.8c-1.2,0-1.8-.7-1.8-1.9Z"/>
    </g>
  </g>
</svg>
);

export default IconAI;