// PageFooter.js
import React from 'react';
import CookieConsent from "react-cookie-consent";

function PageFooter() {
  const scrollToTopFtr = () => { 
    if (window) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      console.log('element not found'); 
    }
  };

  return (
    <footer id='footer' className='react-container'> 
    <CookieConsent 
    location="bottom"
    buttonText="Sure thing"
    style={{ background: "#d0d0d0" }}
    buttonStyle={{ color: "#ffffff", background: "#ff91c2", marginTop: "0", fontSize: "16px", padding: "8px 24px", borderRadius: "30px" }}
    expires={150}  
    buttonClasses="cookie-consent-btn"
    >This website uses cookies to enhance the user experience.</CookieConsent>
    <div className='page-footer'>
      <div className='page-footer__socials'>  
        <div className='page-footer__socials-container'>
            <a href='https://www.linkedin.com/in/klaudiatugul/' title='Klaudia Tugul LinkedIn' target='_blank'>
              <i className='fa-brands fa-linkedin-in'></i>
              </a>
          <a href='mailto:hello@klaudiatugul.com' title='Klaudia Tugul Email'>
            <i className='fa-solid fa-at'></i>
          </a>
        </div>
      </div>
      <div className='page-footer__scroll' onClick={scrollToTopFtr}>Scroll to top</div>
      <div className='page-footer__credits'>&copy; Klaudia Tuğul 2024</div>
    </div>
    </footer>
  );
}

export default PageFooter;
