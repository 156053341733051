import React from 'react';

const IconJira = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300">
  <g>
    <g>
      <g>
        <g>
          <path className="fill-lightblue" d="M277.8,10.5h-134.3c0,16.1,6.4,31.5,17.8,42.9,11.4,11.4,26.8,17.8,42.9,17.8h24.7v23.9c0,33.5,27.1,60.6,60.6,60.6V22.2c0-6.4-5.2-11.7-11.7-11.7Z"/>
          <path className="fill-lightblue" d="M211.4,77.5H77c0,33.5,27.1,60.6,60.6,60.6h24.7v24c0,33.5,27.2,60.6,60.6,60.6V89.1c0-6.4-5.2-11.7-11.7-11.7Z"/>
          <path className="fill-lightblue" d="M144.9,144.3H10.5c0,33.5,27.1,60.6,60.6,60.6h24.8v23.9c0,33.4,27.1,60.5,60.5,60.6v-133.5c0-6.4-5.2-11.7-11.7-11.7Z"/>
        </g>
      </g>
    </g>
  </g>
</svg>
);

export default IconJira;