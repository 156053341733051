import React from 'react';

const IconAsana = () => (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300">
    <g>
      <g>
        <path className="fill-neonpeachy" d="M232.7,156.6c-35.2,0-63.7,28.5-63.7,63.7s28.5,63.7,63.7,63.7,63.7-28.5,63.7-63.7-28.5-63.7-63.7-63.7h0ZM67.3,156.6c-35.2,0-63.7,28.5-63.7,63.7s28.5,63.7,63.7,63.7,63.7-28.5,63.7-63.7-28.5-63.7-63.7-63.7h0ZM213.7,77c0,35.2-28.5,63.7-63.7,63.7s-63.7-28.5-63.7-63.7,28.5-63.7,63.7-63.7,63.7,28.5,63.7,63.7h0Z"/>
      </g>
    </g>
  </svg>
);

export default IconAsana;