import React from 'react';

const IconCss = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300"> 
  <g>
    <g>
      <g>
        <g>
          <polygon id="polygon2989" className="fill-smokeblue" points="256.3 61.2 237.4 273.8 152.1 297.4 67 273.8 48.1 61.2 256.3 61.2"/>
          <polygon id="polygon2991" className="fill-smokeblue2" points="221.1 260.2 237.3 78.6 152.2 78.6 152.2 279.3 221.1 260.2"/>
          <polygon id="polygon2993" className="fill-darkwhite" points="91.5 157.4 93.9 183.5 152.2 183.5 152.2 157.4 91.5 157.4"/>
          <polygon id="polygon2995" className="fill-darkwhite" points="152.2 104.7 152.1 104.7 86.8 104.7 89.2 130.7 152.2 130.7 152.2 104.7"/>
          <polygon id="polygon2997" className="fill-darkwhite" points="152.2 252.3 152.2 225.1 152.1 225.2 123.1 217.3 121.2 196.5 107.1 196.5 95 196.5 98.7 237.5 152.1 252.3 152.2 252.3"/>
          <path id="path2999" className="fill-black" d="M97.1,3.4h31.6v13.2h-18.4v13.2h18.4v13.2h-31.6V3.4Z"/>
          <path id="path3001" className="fill-black" d="M135,3.4h31.6v11.5h-18.4v2.3h18.4v26.4h-31.6v-12.1h18.4v-2.3h-18.4V3.4Z"/>
          <path id="path3003" className="fill-black" d="M172.9,3.4h31.6v11.5h-18.4v2.3h18.4v26.4h-31.6v-12.1h18.4v-2.3h-18.4V3.4Z"/>
          <polygon id="polygon3005" className="fill-white" points="184.2 183.5 181.2 217.3 152.1 225.2 152.1 252.3 205.6 237.5 206 233.1 212.1 164.4 212.7 157.4 217.4 104.7 152.1 104.7 152.1 130.7 188.9 130.7 186.5 157.4 152.1 157.4 152.1 183.5 184.2 183.5"/>
        </g>
      </g>
    </g>
  </g>
</svg>
);

export default IconCss;