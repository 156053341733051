import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import { OrbitControls } from '@react-three/drei';

const RotatingSphere = () => {
  const sphereRef = useRef();
  const texture = useLoader(TextureLoader, '/models/final/moontxture2.jpg'); 
  const [sphereSize, setSphereSize] = useState(3); 

  useEffect(() => {
    const updateSphereSize = () => {
      if (window.innerWidth < 1100) {
        setSphereSize(2); 
      } else {
        setSphereSize(3);
      }
    };

    window.addEventListener('resize', updateSphereSize);
    updateSphereSize(); 

    return () => {
      window.removeEventListener('resize', updateSphereSize);
    };
  }, []);

  useFrame(() => {
    if (sphereRef.current) {
      sphereRef.current.rotation.y += 0.001; 
    }
  });

  return (
    <mesh ref={sphereRef} position={[0, 0, 0]} castShadow receiveShadow>
      <sphereGeometry args={[sphereSize, 32, 32]} /> 
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}; 

const ThreeSphere = () => {
  return (
    <Canvas camera={{ position: [0, 0, 5], fov: 50 }}>
      <ambientLight intensity={1} color="white" />
      <directionalLight 
        position={[5, 5, 5]} 
        intensity={1}
        castShadow
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-bias={-0.0001}   
      />
      <RotatingSphere />
      <OrbitControls 
        enableDamping={true}
        dampingFactor={0.25}
        screenSpacePanning={false}
        minDistance={10}
        maxDistance={50}
        enableZoom={false}
        enableRotate={false}
      />
    </Canvas>
  );
};

export default ThreeSphere;
