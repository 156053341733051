// ContactMe.js
import React, { useState, useEffect } from 'react';

function ContactMe() {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  useEffect(() => {
    const inputs = document.querySelectorAll('.section-contact__form input, .section-contact__form textarea');
    
    inputs.forEach(input => {
      const label = input.closest('.section-contact__form__input').querySelector('label');
      if (input.value.trim() !== '') {
        label.classList.add('hidden');
      } else {
        label.classList.remove('hidden');
      }
    });
  }, [formData]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append('name', formData.name);
    form.append('email', formData.email);
    form.append('phone', formData.phone);
    form.append('message', formData.message);

    fetch('./php/contact.php', {
      method: 'POST',
      body: form,
    })
    .then(response => {
      console.log(response);
      return response.text();
    })
    .then(data => {
      console.log(data);
      alert('Message sent successfully!');
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Failed to send message.');
    });
  };

  return (
    <section className='section-contact' id='sectionContact'>
    

                <div className='section-contact__body'>
                  
                <div className='section-contact__form-container'>
                <form className='section-contact__form' onSubmit={handleSubmit}>
                <h2 className='section-contact__header-h2 header-h2 section-contact__form-header'>Get in <span>T</span><span>O</span><span>U</span><span>C</span><span>H</span>
                    </h2> 
      <div className='section-contact__form__input section-contact__form__input-name'>
          <label htmlFor='name'>Name:</label>
          <input
            type='text'
            id='name'
            name='name'
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className='section-contact__form__input section-contact__form__input-email'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            id='email'
            name='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className='section-contact__form__input section-contact__form__input-phone'>
          <label htmlFor='phone'>Phone:</label>
          <input
            type='tel'
            id='phone'
            name='phone'
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className='section-contact__form__input section-contact__form__input-message'>
          <label htmlFor='message'>Message:</label>
          <textarea
            id='message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type='submit' className='section-contact__form__submit'>Send Email</button>
      </form>
                </div> </div>

    </section>
  );
}

export default ContactMe;
