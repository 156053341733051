import React, { useState, useEffect } from 'react';
import pdfFile from '../../assets/KlaudiaTugulCV.pdf';
import { handleResize, updateTheme } from './../global/utils';

const NavComponent = () => {
    const [scrolled, setScrolled] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };
  
        window.addEventListener('resize', handleResize);
  
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); 

    const [isDarkTheme, setIsDarkTheme] = useState(false);

    const toggleTheme = () => {
        const htmlElement = document.documentElement;
        if (isDarkTheme) {
            htmlElement.setAttribute('data-theme', 'color');
        } else {
            htmlElement.setAttribute('data-theme', 'dark');
        }
        setIsDarkTheme(!isDarkTheme);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 300) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleMobileClick = (scrollFunction) => {
        if (isMobile) {
            const menuCloseButton = document.querySelector('.menu-icon.close');
            if (menuCloseButton) {
                menuCloseButton.click();
            }
        }
        scrollFunction();
    };

    const scrollToTopMenu = () => { 
        if (window) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          } else {
            console.log('element not found'); 
          }
      };

      const scrollToContactSection = () => { 
        const contactFormElem = document.querySelector('#sectionContact');
    
        if (contactFormElem) {
            contactFormElem.scrollIntoView({ behavior: 'smooth' }); 
        } else {
          console.log('element not found'); 
        }
      };

    return (
        <ul className='nav__list'>
            <li className='nav__list-item'>
                <a className={`hover-target ${scrolled ? 'opacity' : ''}`} onClick={() => handleMobileClick(scrollToTopMenu)}>
                    main
                </a>
            </li>
            <li className='nav__list-item'>
                <a href='#' className={`hover-target ${scrolled ? 'opacity' : ''}`} onClick={() => handleMobileClick(scrollToContactSection)}>
                    contact me
                </a>
            </li>
            <li className='nav__list-item'>
                <a href={pdfFile} target="_blank" rel="noopener noreferrer" className={`hover-target ${scrolled ? 'opacity' : ''}`}>
                    download my resume
                </a>
            </li>
            {isMobile ? <></> : 
              <li className='nav__list-item'>
                <a id="themeToggleDesktop" onClick={toggleTheme} className={`hover-target theme-toggler ${scrolled ? 'opacity' : ''}`}>
                    {isDarkTheme ? 'Day Mode' : 'Night Mode'}
                </a>
            </li>
            }
        </ul>
    );
};

export default NavComponent;
