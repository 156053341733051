import { createRoot } from 'react-dom/client';
import React, { useEffect, useState } from 'react';
import '@fortawesome/fontawesome-free/js/all.js';

import PagePreloader from './components/PagePreloader';
import PageMenu from './components/PageMenu';
import PageHeader from './components/PageHeader';
import SectionQuote from './components/SectionQuote';
import SectionBottom from './components/SectionBottom';
import AboutMe from './components/AboutMe';
import SectionSkills from './components/SectionSkills';
//import HowIWork from './components/HowIWork';
//import MyProjects from './components/MyProjects';
import WhatIDo from './components/WhatIDo';
import ContactMe from './components/ContactMe';
import PageFooter from './components/PageFooter';


const rootApp = createRoot(document.getElementById('app'));
 
function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 800);
    
    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <PagePreloader />;
  }

  return (
    <React.Fragment>
      <PageMenu />
      <PageHeader />
      <SectionQuote />
      <SectionBottom />
      <AboutMe />
      <WhatIDo />
      <SectionSkills />
      <ContactMe />
      <PageFooter />
    </React.Fragment>
  );
}
 
rootApp.render(<App />);
 
import "./css/styles.min.css";
import './css/animate.min.css';
import "./js/scripts.js";
import "./js/animejsPlugins-all.js";
