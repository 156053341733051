import React from 'react';

const IconSEO = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300"> 
  <g>
    <g>
      <g>
        <path className="fill-rule-evenodd ill-babyblue" d="M166.2,51c49.2,0,89.1,39.9,89.1,89.1s-39.9,89.1-89.1,89.1-47.9-10.4-64.1-27.2h28.2c2.1,0,4.1-.3,6-.9,9,4.4,19.2,6.9,29.9,6.9,37.5,0,68-30.4,68-68s-30.4-68-68-68-21.3,2.6-30.5,7.2c-2.2-.8-4.7-1.3-7.2-1.3h-26.2c16.2-16.7,38.9-27.1,64-27.1h0Z"/>
        <path className="fill-dirtygray" d="M68.1,121.7h-26v-6.9c0-3.2-.4-5.3-1-6.2-.7-.9-1.8-1.3-3.3-1.3s-2.9.6-3.8,1.8c-.9,1.2-1.3,3-1.3,5.4s.5,5.4,1.5,7c1,1.5,3.6,3.5,7.9,5.7,12.4,6.4,20.2,11.7,23.5,15.8,3.2,4.1,4.9,10.7,4.9,19.8s-.9,11.5-2.7,14.7-5.3,5.8-10.4,7.9c-5.1,2.1-11.1,3.2-17.9,3.2s-13.9-1.2-19.2-3.7c-5.3-2.5-8.7-5.6-10.4-9.4-1.6-3.8-2.5-9.2-2.5-16.2v-6.1h26v11.3c0,3.5.4,5.7,1.1,6.7.7,1,2,1.5,3.9,1.5s3.2-.6,4.2-1.9c.9-1.3,1.4-3.1,1.4-5.6,0-5.5-.9-9.1-2.6-10.7-1.8-1.7-6.1-4.5-13.1-8.5-7-4-11.5-6.9-13.8-8.7-2.2-1.8-4.1-4.3-5.6-7.5-1.5-3.2-2.2-7.3-2.2-12.2,0-7.1,1.1-12.3,3.2-15.6,2.1-3.3,5.5-5.9,10.2-7.7,4.7-1.9,10.3-2.8,17-2.8s13.4,1,18.6,3c5.1,2,8.5,4.6,10.2,7.7,1.7,3.1,2.5,8.3,2.5,15.7v3.7h0ZM166.1,39.8c27.7,0,52.8,11.2,70.9,29.4,18.1,18.1,29.4,43.2,29.4,70.9s-6,39.1-16.4,54.9l43.4,47.3-29.9,27.4-41.9-46.1c-15.9,10.6-35,16.8-55.6,16.8s-52.8-11.2-70.9-29.4c-2.8-2.8-5.5-5.9-8-9.1h14.8c.4.4.7.8,1.1,1.1,16.1,16.1,38.4,26.1,63,26.1s46.9-10,63-26.1c16.1-16.1,26.1-38.4,26.1-63s-9.9-46.9-26.1-63h0c-16.1-16.1-38.4-26.1-63-26.1s-46.9,10-63,26.1c-.3.3-.6.6-.9.9h-14.9c2.5-3.1,5.1-6.1,7.9-8.8,18.2-18.2,43.2-29.4,70.9-29.4h0ZM80.4,93.5h48v18.6h-19.9v17.8h18.7v17.6h-18.7v20.5h21.8v18.6h-49.9v-93.1h0ZM204.5,148c0,9.4-.3,16-.8,19.8-.5,3.9-2.1,7.4-4.8,10.7s-6.3,5.7-10.9,7.4c-4.6,1.7-9.9,2.6-15.9,2.6s-10.9-.8-15.5-2.5c-4.6-1.6-8.3-4.1-11.1-7.3-2.8-3.2-4.5-6.8-5-10.6-.5-3.9-.8-10.6-.8-20.1v-15.9c0-9.4.3-16,.8-19.8.5-3.9,2.1-7.4,4.8-10.7s6.3-5.7,10.9-7.4c4.6-1.7,9.9-2.6,15.9-2.6s10.9.8,15.5,2.5c4.6,1.6,8.3,4.1,11.1,7.3,2.8,3.3,4.5,6.8,5,10.6.5,3.9.8,10.6.8,20.1v15.9h0ZM176.4,117.5c0-4.3-.3-7.1-.8-8.3-.5-1.2-1.7-1.8-3.3-1.8s-2.5.5-3.3,1.5c-.7,1-1.1,3.9-1.1,8.6v43.4c0,5.4.3,8.7.7,10,.5,1.3,1.7,1.9,3.5,1.9s3.1-.7,3.6-2.2.8-4.9.8-10.4v-42.8h0Z"/>
      </g>
    </g>
  </g>
</svg>
);

export default IconSEO;