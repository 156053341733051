// AboutMe.js
import React, { useEffect, useState } from 'react';
import * as ScrollMagic from "scrollmagic";
import { gsap } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap"; 
import { handleResize } from './global/utils';
import { updateTheme } from './global/utils';
import AboutMeYou from './snippets/AboutMeYou';

ScrollMagicPluginGsap(ScrollMagic, gsap);


function AboutMe() {

  const [isMobile, setIsMobile] = useState(window.innerWidth < 700);
  
  useEffect(() => {
    const cleanUpResize = handleResize(setIsMobile, 700);  

    return () => {
      cleanUpResize();
    };
  }, []);

  const [theme, setTheme] = useState(document.documentElement.getAttribute('data-theme'));

  useEffect(() => {
    const controller = new ScrollMagic.Controller();
    
    if (document.getElementById('sectionAboutMe') && document.getElementById('SectionBottom')) {
      const sceneAboutMe = new ScrollMagic.Scene({
        triggerElement: '#SectionBottom',
        duration: "200%",
        triggerHook: 1   
      })
      .setTween('#sectionAboutMe > div', { y: "70%", ease: "none" })
      .addTo(controller);
    } else {
      console.log('fail');
    }

    // Użycie updateTheme z utils.js
    const cleanUpTheme = updateTheme(setTheme);

    return () => {
      cleanUpTheme(); // Rozłączanie observera
      controller.destroy(true);
    };
  }, []);

  return (
    <section className='section-about-me' id='sectionAboutMe'>
      {theme === 'dark' ? (
      <div className='section-about-me__stars'>
      <span className='section-about-me__stars-star'></span>
      <span className='section-about-me__stars-star'></span>
      <span className='section-about-me__stars-star'></span> 
    </div>
      ) : (
      <></>
      )
      
    }
      <div className='section-about-me__content'>
        <div className='section-about-me__content__photo'>
          <img src={require('../assets/about-me-img_v2_2.png')} alt="Klaudia Tuğul Portfolio"/>
        </div>
        {theme === 'dark' ? (
          <div className='section-about-me__content-container'>
            <div className='section-about-me__content__header'>Nice to meet</div>
            <div className='section-about-me__content__header-hl'>
              <div><span>Y</span><span>O</span><span>U</span></div>
            </div>
            <p className='section-about-me__content__description'>Hello! I’m Klaudia, a passionate coder since I was 13. My love for UX and graphic design started early, and after a few years in the banking industry, I shifted to e-commerce five years ago. I'm a big-picture thinker with a knack for bringing bold ideas to life. My greatest satisfaction comes from helping small businesses grow and thrive, competing with the biggest players in the industry.</p>
          </div>
        ) : (
          <>
            <div className='section-about-me__content__header'>Nice to meet{isMobile ? <></> : <AboutMeYou /> }</div>
            {isMobile ? <AboutMeYou /> : <></> }
            <p className='section-about-me__content__description'>Hello! I’m Klaudia, a passionate coder since I was 13. My love for UX and graphic design started early, and after a few years in the banking industry, I shifted to e-commerce five years ago. I'm a big-picture thinker with a knack for bringing bold ideas to life. My greatest satisfaction comes from helping small businesses grow and thrive, competing with the biggest players in the industry.</p>
          </>
        )}
      </div>
    </section>
  );
}

export default AboutMe;
