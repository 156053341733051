import React from 'react';

const IconPS = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300">
  <g>
    <g>
      <g>
        <g>
          <g>
            <g>
              <path className="fill-darkblue" d="M55.7,7.6h188.6c28.6,0,51.7,23.1,51.7,51.7v181.3c0,28.6-23.1,51.7-51.7,51.7H55.7c-28.6,0-51.7-23.1-51.7-51.7V59.4C4,30.8,27.1,7.6,55.7,7.6Z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <path className="fill-lightblue" d="M69.7,207.3v-125.2c0-.9.4-1.3,1.2-1.3,2.1,0,4,0,6.8-.1,2.9-.1,6-.1,9.2-.2,3.3-.1,6.8-.1,10.6-.2,3.8-.1,7.4-.1,11.1-.1,10,0,18.2,1.2,25.1,3.8,6.1,2.1,11.7,5.5,16.3,10,3.9,3.9,6.9,8.6,8.9,13.9,1.8,5.1,2.8,10.3,2.8,15.8,0,10.5-2.4,19.1-7.3,25.9s-11.7,11.9-19.6,14.8c-8.3,3-17.4,4.1-27.4,4.1s-4.9,0-6.1-.1-2.9-.1-5.2-.1v39.1c.1.9-.5,1.6-1.3,1.7h-23.6c-1,0-1.5-.5-1.5-1.6ZM96.2,104.3v40.9c1.7.1,3.3.2,4.7.2h6.4c4.7,0,9.5-.7,14-2.2,3.9-1.1,7.3-3.4,10-6.4,2.6-3,3.8-7.2,3.8-12.5.1-3.8-.9-7.5-2.8-10.8-2.1-3.2-5-5.6-8.5-6.9-4.5-1.8-9.4-2.6-14.4-2.4-3.2,0-6,0-8.3.1-2.4-.1-4.1,0-5,.1h0Z"/>
            <path className="fill-lightblue" d="M237.6,137.7c-3.6-1.9-7.5-3.3-11.7-4.1-4.5-1-9-1.6-13.6-1.6-2.4-.1-5,.2-7.3.9-1.6.4-2.9,1.2-3.8,2.4-.6,1-1,2.2-1,3.3s.5,2.2,1.2,3.2c1.1,1.3,2.6,2.4,4.1,3.3,2.8,1.5,5.7,2.8,8.6,4,6.6,2.2,12.9,5.2,18.7,8.9,4,2.6,7.3,6,9.6,10.1,1.9,3.9,2.9,8.2,2.8,12.5.1,5.7-1.6,11.4-4.7,16.2-3.4,4.9-8.2,8.6-13.6,10.8-6,2.6-13.3,3.9-22,3.9s-11.1-.5-16.5-1.6c-4.3-.7-8.5-2.1-12.4-3.9-.9-.5-1.5-1.3-1.3-2.3v-21.2c0-.4.1-.9.5-1.1.4-.2.7-.1,1.1.1,4.7,2.8,9.7,4.7,15.1,6,4.6,1.2,9.5,1.8,14.4,1.8s7.9-.6,10.1-1.7c1.9-.9,3.3-2.9,3.3-5.1s-1-3.3-2.9-4.9c-1.9-1.6-6-3.4-11.9-5.7-6.2-2.2-11.9-5.1-17.3-8.8-3.8-2.7-6.9-6.2-9.2-10.3-1.9-3.9-2.9-8.2-2.8-12.4,0-5.2,1.5-10.2,4.1-14.7,3-4.9,7.5-8.8,12.8-11.2,5.7-2.9,12.9-4.3,21.5-4.3s10.1.4,15.1,1.1c3.6.5,7.2,1.5,10.5,2.8.5.1,1,.6,1.2,1.1.1.5.2,1,.2,1.5v19.8c0,.5-.2,1-.6,1.2-1.1.2-1.7.2-2.2,0Z"/>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
);

export default IconPS;