import React from 'react';

const IconNetsuite = () => (
<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 300 300">
  <g>
    <g>
      <path className="fill-pastelsky" d="M21.2,89h55.7v132.3h27.7v51.7H21.2V89ZM279,210.8h-55.7V78.4h-27.7V26.7h83.4v184.1Z"/>
      <path className="fill-darkblue" d="M8.3,14h176.1v151.1l-67.7-87.2H8.3V14ZM291.7,286H115.7v-151.1l67.7,87.2h108.4"/>
    </g>
  </g>
</svg>
);

export default IconNetsuite;