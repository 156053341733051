import React from 'react';

const IconEcom = () => (
<svg id="iconEcom" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.84 44.31">
 
  <g>
    <path d="m3.65,9.78v5.15h71.68v-5.15H3.65Z" fill="none" className='stroke-base' strokeMiterlimit="10" strokeWidth="4.04"  />
    <path d="m70.18,2.02H8.68c-3.66,0-6.91,3.01-6.64,6.64v27c0,3.66,2.97,6.62,6.62,6.62h61.54c3.66,0,6.62-2.97,6.62-6.62V8.66c0-3.66-2.97-6.64-6.64-6.64Zm5.15,12.91H3.65v-5.15h71.68v5.15Z" fill="none" className='stroke-base' strokeMiterlimit="10" strokeWidth="4.04" id="iconEcomPathAnimate"/>
    <rect x="3.58" y="9.78" width="71.68" height="5.15" rx=".57" ry=".57" className="color-fill" strokeWidth="0"/>
  </g>
  <g>
    <rect x="7.6" y="36.13" width="12.66" height="1.08" rx=".1" ry=".1" className="color-fill" strokeWidth="0"/>
    <rect x="22.1" y="36.13" width="7.98" height="1.08" rx=".1" ry=".1" className="color-fill" strokeWidth="0"/>
  </g>
</svg>
);

export default IconEcom;